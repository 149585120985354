(function () {
  'use strict';

  angular
  .module('neo.home.ajuntaments')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.ajuntaments', {
      url: '/ajuntaments',
      templateUrl: 'home/ajuntaments/ajuntaments.tpl.html',
      controller: 'AjuntamentsCtrl',
      controllerAs: 'vm',
      resolve: {
        ajuntaments: function (Ajuntaments, $q) {
          var deferred = $q.defer();

          Ajuntaments.query(
            function (data) {
              deferred.resolve(data);
            },
            function (response) {
              deferred.reject(response);
            });
          return deferred.promise;
        },
         ajuntamentsread: function (Ajuntamentsread, $q) {
          var deferred = $q.defer();

          Ajuntamentsread.query(
            function (data) {
              deferred.resolve(data);
            },
            function (response) {
              deferred.reject(response);
            });
          return deferred.promise;
        }
      }
    })
            .state('home.jvp',{
                url:'/jvp',
                templateUrl: 'home/ajuntaments/jvp.tpl.html',
                controller: 'Jvp',
                controllerAs: 'vm',
                resolve:{
                 townHalls: function (Ajuntaments) {
                    return Ajuntaments.query().$promise;
          }}
    }).state('home.jvp2',{
                url:'/jvp2',
                templateUrl: 'home/ajuntaments/jvp2.tpl.html',
                controller: 'Jvp2',
                controllerAs: 'vm',
                resolve:{  
             
                 townHalls: function (Ajuntaments) {
                    return Ajuntaments.query().$promise;
          }}
    });
  }
}());
